import { Container, Typography, Stack, Card } from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import React, { useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import IntroHead from '#/components/shared/IntroHead';
import useLocales from '#/hooks/useLocales';
import { FormProvider } from '#/components/shared/hook-form';
import useAuth from '#/hooks/useAuth';
import {
  initialValues,
  registerValidation,
} from '#/components/pages/PensionProvision/onboarding/steps/registration/validations';
import { fToDB } from '#/utils/formatTime';
import FirstStep from '#/components/pages/PensionProvision/onboarding/steps/registration/first-step';
import AgreeCheckbox from '#/components/pages/PensionProvision/onboarding/steps/registration/agree-checkbox';

const STEPS_TITLES = ['pensionProvision.onboarding.stepOne.title'];

export default function Onboarding() {
  const [agree, setAgree] = useState(false);
  const [searchParams] = useSearchParams();

  const { translate, currentLang } = useLocales();
  const { register, isLoading } = useAuth();

  const navigate = useNavigate();
  const handleAgreeChange = (e: boolean) => {
    setAgree(e);
  };

  const fromPartner = localStorage.getItem('gg_partner_key');

  const defaultValues = useMemo(
    () => initialValues(currentLang.value),
    [currentLang]
  );

  const methods = useForm({
    resolver: yupResolver(registerValidation as any),
    defaultValues,
    mode: 'onBlur',
  });
  const { handleSubmit } = methods;

  const onSubmit = async (data: any) => {
    const { hostname } = window.location;
    const domainParts = hostname.split('.');
    const subdomain =
      domainParts.length > 2 ? domainParts.slice(0, -2).join('.') : '';

    const subscription_package = searchParams.get('subscription_package');
    const body = {
      ...data,
      date_of_birth: fToDB(data.date_of_birth),
      email: data.email.toLowerCase(),
      ...(fromPartner && { partner: fromPartner }),
      ...(subdomain && !subdomain.includes('localhost') && { subdomain }),
      ...(subscription_package && {
        subscription_package,
      }),
    };

    await register(body)
      .then(() => {
        navigate(`/vorsorge/schritt=2`, {
          state: {
            redirectLink: searchParams.get('redirectLink'),
            step: 2,
          },
        });
      })
      .catch((err) => err.errors.map((error: string) => toast.error(error)));
  };

  const currentTitle = translate(STEPS_TITLES[0]);

  return (
    <Container maxWidth="lg" sx={{ marginY: 12 }}>
      <IntroHead title={String(currentTitle)} description="" />
      <Typography
        variant="body2"
        gutterBottom
        textAlign="center"
        color="primary"
        sx={{ marginBottom: 6 }}
      >
        {String(translate('pensionProvision.alreadyHaveAcc'))} &nbsp;
        <Link
          to="/auth/login"
          style={{
            color: '#3366FF',
          }}
        >
          {String(translate('pensionProvision.signIn'))}
        </Link>
      </Typography>
      <Stack sx={{ width: '100%', marginTop: 3 }} spacing={4}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Card sx={{ p: 3, mt: 4 }}>
            <FirstStep />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ marginTop: 4 }}
              spacing={2}
            >
              <AgreeCheckbox onAgreeChange={handleAgreeChange} agree={agree} />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                disabled={!agree}
                sx={{ width: '20%' }}
              >
                <Typography>{String(translate('global.continue'))}</Typography>
              </LoadingButton>
            </Stack>
          </Card>
        </FormProvider>
      </Stack>
    </Container>
  );
}
