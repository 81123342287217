import {
  Checkbox,
  FormControlLabel,
  Link as Anchor,
  Stack,
  Typography,
} from '@mui/material';
import useLocales from '#/hooks/useLocales';

type Props = {
  onAgreeChange: (value: boolean) => void;
  agree: boolean;
};

export default function AgreeCheckbox({ onAgreeChange, agree }: Props) {
  const { translate } = useLocales();

  return (
    <Stack direction="column" justifyContent="flex-start" sx={{ width: '60%' }}>
      <FormControlLabel
        sx={{
          '& .MuiTypography-root': {
            fontSize: '0.875rem',
          },
        }}
        control={
          <Checkbox
            checked={agree}
            onChange={(e) => onAgreeChange(e.target.checked)}
          />
        }
        label={String(translate('pensionProvision.confirmCheckbox'))}
      />
      <Anchor
        sx={{
          textDecoration: 'none',
        }}
        href="https://storage.gutgeregelt.ch/docs/gut_geregelt_DPA.pdf"
        target="_blank"
      >
        <Typography variant="body2" component="span" color="primary">
          {String(translate('pensionProvision.processingAgreement'))}
        </Typography>
      </Anchor>
    </Stack>
  );
}
